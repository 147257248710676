export enum ReferenceType {
  Null = 0,
  Company = 1,
  Project = 2,
  Floor = 3,
  Department = 4,
  Room = 5,
  Component = 6,
  Library = 7,
  AdvancedWorkPackage = 8,
  Scenario = 9,
  Category = 10,
  Collection = 11,
  PeriodicTable = 12,
  Block = 13,
  Part = 14,
  DepartmentRoom = 15,
  RoomContent = 16,
  ContentOptions = 17,
  Program = 18,
  Source = 19,
  ProjectCatalog = 20,
  DR_RC_B = 21,
  Bundle = 22,
  RoomDataCategory = 23,
  RoomContentCategory = 24,
};
