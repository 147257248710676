//angular
import {
  computed,
  Injectable,
  signal,
  WritableSignal
} from '@angular/core';

//local
import { Right } from '../_interfaces/right';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class UserPermissionsService {
  public rights: WritableSignal<Right[]> = signal<Right[]>([]);

  public canConfigureCatalog = computed(() => {
    let r = this.rights().find(i => i.id == '28832' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  public canViewCatalog = computed(() => {
    if (this.canConfigureCatalog()) return true;
    let r = this.rights().find(i => i.id == '24768' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  public canViewLibrary = computed(() => {
    if (this.canEditLibrary() || this.canConfigureLibrary() || this.canDeleteLibrary()) return true;
    let r = this.rights().find(i => i.id == '16480' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  public canAddLibrary = computed(() => {
    let r = this.rights().find(i => i.id == '20712' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  public canEditLibrary = computed(() => {
    let r = this.rights().find(i => i.id == '20712' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  public canDeleteLibrary = computed(() => {
    let r = this.rights().find(i => i.id == '16632' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  public canConfigureLibrary = computed(() => {
    let r = this.rights().find(i => i.id == '16632' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  public canViewSpacebot = computed(() => {
    let r = this.rights().find(i => i.id == '32960' && i.isActive && !i.isDeleted);
    return r !== undefined;
  });

  constructor(
    private userService: UserService
  ) {
    this.userService.getRights().subscribe((rights) => {
      this.rights.set(rights);
    });
  }
}