import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';

import {
  FilterGroupV2,
  FilterV2,
  FiltersPaneMode,
  FilterService,
} from '../../';

@Component({
  selector: 'filters-list',
  templateUrl: './filters-list.component.html',
  styleUrls: ['./filters-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

@Injectable({
  providedIn: 'root'
})

export class FiltersListComponent implements OnInit {
  @Input() definition: FilterGroupV2;
  @Input() editMode: WritableSignal<boolean>;
  @Input() mode: FiltersPaneMode;
  @Output() filterChecked = new EventEmitter<string>();
  @Input() documentCount: WritableSignal<number>;
  FiltersPaneMode = FiltersPaneMode;
  showingAll: WritableSignal<boolean> = signal(false);
  public collapsed: WritableSignal<boolean> = signal<boolean>(true);

  public totalCheckedCount = computed<number>(() => {
    return this.definition.totalCheckedCount();
  });

  public visibleCount = computed<number>(() => {
    return this.definition.totalVisibleCount();
  });

  public totalFilterCount = computed<number>(() => {
    return this.definition.totalFilterCount();
  });

  public totalVisibleCount = computed<number>(() => {
    return this.definition.totalVisibleCount();
  });

  public visible = computed<boolean>(() => {
    if (this.editMode() && this.mode != FiltersPaneMode.Navigation) return true;
    if (this.definition.totalEnabledCount() == 0) return false;
    if (this.mode !== undefined) {
      switch (this.mode) {
        case FiltersPaneMode.Navigation:
          return this.totalFilterCount() > 0;
          break;
        case FiltersPaneMode.Document:
          if (this.editMode()) {
            return this.totalVisibleCount() > 0;
          } else {
            return this.totalCheckedCount() > 0 && this.totalVisibleCount() > 0;
          }
          break;
        case FiltersPaneMode.Upload:
          return this.definition.totalVisibleCount() > 0;
          break;
      }
    }
    return true;
  });

  public totalEnabledCount = computed<number>(() => {
    return this.definition.totalEnabledCount();
  });

  constructor(
    private filterService: FilterService,
  ) {
  }

  ngOnInit(): void {
    this.definition.collapsed = this.collapsed;
    this.definition.editMode = this.editMode;
    this.definition.documentCount = this.documentCount;
    this.definition.evaluateFilters(false);
  }

  search(searchText: string): void {
    this.definition.search(searchText);
  }

  filterChanged(filter: FilterV2, event: any): void {
    filter.checked.set(event.target.checked);
    if (this.editMode() && this.mode != FiltersPaneMode.Navigation && !filter.checked() && this.definition.totalCheckedCount() == 0) {
      let fList = this.definition;
      let parentChecked = false;
      while (fList !== undefined && !parentChecked) {
        let parent = fList.hierarchyParent;
        if (parent.totalCheckedCount() == 0) fList.setFilters([]);
        else parentChecked = true;
        fList = parent;
      }
    }
    this.filterChecked.emit(this.definition.name);
  }

  toggleCollapsed(): void {
    this.collapsed.set(!this.collapsed());
    this.definition.evaluateFilters(false);
  }

  reset() {
    this.definition.setFilters([]);
    this.showingAll.set(false);
  }

  showAll(): void {
    let checkedFilters: FilterV2[][] = this.definition.getCheckedHierarchy(true);
    this.filterService.getNextLevelFilters(this.definition.hierarchyParent.getUrl(), checkedFilters)
      .subscribe((filterDtos) => {
        this.definition.setFilters(FilterV2.FromFilterV2Dtos(filterDtos));
        this.showingAll.set(true);
      });
  }
}










