import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';

import {
  DocumentFilters,
  FilterContext,
  FilterGroupV2,
  FiltersListComponent,
  FiltersPaneMode,
  FiltersPanelDefinition,
  FilterService,
  FilterV2,
  FilterV2Dto,
  GetDocumentFiltersResponse,
  BaseFilters,
} from '../../';

import {
  expandCamelCase,
} from '../../../_helpers/camelCaseHelper';

import {
  Dictionary
} from '../../../_types/dictionary';

@Component({
  selector: 'filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['filters-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

@Injectable({
  providedIn: 'root'
})

export class FiltersPanelComponent implements OnInit {
  @Input() definition: FiltersPanelDefinition;
  @Input() editMode: WritableSignal<boolean>;
  @Input() mode: FiltersPaneMode;
  @Input() documentCount: WritableSignal<number>;

  @ViewChildren(FiltersListComponent) filterLists: QueryList<FiltersListComponent>;
  heading: WritableSignal<string>;
  filterGroups: WritableSignal<FilterGroupV2[]>;
  collapsed: WritableSignal<boolean> = signal<boolean>(false);

  selectedCount = computed<number>(() =>
    this.filterGroups().reduce((count, fg) => count + fg.totalCheckedCount(), 0)
  );

  @Output() panelCollapseToggled = new EventEmitter<FilterContext>();
  @Output() filterClicked = new EventEmitter<boolean>();
  FiltersPaneMode = FiltersPaneMode;

  lastUserClickCollapsed: boolean = false;

  public totalVisibleCount = computed(() => {
    let count = 0;
    this.definition.filterGroups.forEach((fg) => {
      count += fg.totalVisibleCount();
    });
    return count;
  });

  public totalCheckedCount = computed(() => {
    let count = 0;
    this.definition.filterGroups.forEach((fg) => {
      count += fg.totalCheckedCount();
    });
    return count;
  });

  constructor(
    public filterService: FilterService,
  ) {
    this.heading = signal<string>('');
    this.filterGroups = signal<FilterGroupV2[]>([]);
  }

  toggleCollapsed(): void {
    this.collapsed.set(!this.collapsed());
    this.lastUserClickCollapsed = this.collapsed();
    this.panelCollapseToggled.emit(this.definition.context);
  }

  ngOnInit(): void {
    this.heading.set(expandCamelCase(FilterContext[this.definition.context]));
    this.definition.filterGroups.forEach((fg) => {
      fg.editMode = this.editMode;
    });
    this.filterGroups.set(this.definition.filterGroups);
    if (this.mode == FiltersPaneMode.Navigation) this.collapsed.set(!this.definition.panelOpen);
  }

  filterChecked(groupName: string, checked: Dictionary<string, Dictionary<string, FilterV2Dto>> | undefined = undefined) {
    if (this.mode != FiltersPaneMode.Navigation) {
      let fg: FilterGroupV2 = this.filterGroups().find(x => x.name === groupName);
      if (fg.isHierarchical()) {
        fg.removeAllChildren();
        let childFg = fg.getHierarchyChild();

        let childChecked: boolean = false;
        while (childFg !== undefined && !childChecked) {
          let chk = checked[childFg.name];
          if (chk !== undefined && Object.keys(chk).length > 0) childChecked = true;
          childFg = childFg.getHierarchyChild();
        }

        childFg = fg.getHierarchyChild();
        let checkedFilters: FilterV2[][] = fg.getCheckedHierarchy();
        if (checkedFilters[checkedFilters.length - 1].length > 0 || childChecked) {
          childFg.loading.set(true);
          this.filterService.getNextLevelFilters(fg.getUrl(), checkedFilters)
            .subscribe((filterDtos) => {
              childFg.setFilters(FilterV2.FromFilterV2Dtos(filterDtos));
              if (checked !== undefined) {
                let childCheckedFilters = checked[childFg.name];
                if (childCheckedFilters !== undefined) {
                  childFg.filters().forEach((f) => {
                    let checked: boolean = childCheckedFilters[f.id] !== undefined && childCheckedFilters[f.id].checked;
                    f.checked.set(checked);
                  });
                  this.filterChecked(childFg.name, checked);
                  if (fg.totalCheckedCount() == 0) {
                    let list = this.filterLists.find(x => x.definition.name == childFg.name);
                    list.showingAll.set(true);
                  }
                } else if (childChecked) {
                  // no filters checked on this level but some are further down
                  childFg.setFilters([]);
                  this.filterChecked(childFg.name, checked);
                } else {
                  this.evaluateFilters(true);
                }
                childFg.loading.set(false);
              } else {
                childFg.loading.set(false);
              }
            });
        }
      }

    } else {
      this.filterClicked.emit(true);
    }

    this.evaluateFilters(true);
  }

  public getCheckedFilters(): FilterV2[] {
    let result: FilterV2[] = [];
    this.filterGroups().forEach((fg: FilterGroupV2) => {
      result = [...result, ...fg.getChecked()];
    });
    return result;
  }

  public getcheckedFiltersGrouped(): Dictionary<string, Dictionary<string, FilterV2Dto>> {
    let result: Dictionary<string, Dictionary<string, FilterV2Dto>> = {}
    this.filterGroups().forEach((fg: FilterGroupV2) => {
      fg.filters().forEach((filter) => {
        if (result[fg.name] === undefined) result[fg.name] = {};
        result[fg.name][filter.id] = FilterV2Dto.fromFilterV2(filter);
      });
    });
    return result;
  }

  public search(searchText: string): void {
    this.filterLists.forEach((list) => {
      list.search(searchText);
    });
    if (searchText.length > 0) {
      this.collapsed.set(false);
    } else {
      this.collapsed.set(this.lastUserClickCollapsed);
    }
  }

  public setCheckedFilters(documentFilters: Dictionary<number, Dictionary<string, Dictionary<string, FilterV2Dto>>>): void {
    let filterGroupLookup: Dictionary<string, Dictionary<string, FilterV2Dto>> = documentFilters[this.definition.context];
    if (filterGroupLookup === undefined) filterGroupLookup = {};
    if (this.definition.context === FilterContext.Company) {
      this.filterGroups().forEach((fg) => {
        let filterLookup: Dictionary<string, FilterV2Dto> = filterGroupLookup[fg.name];
        if (filterLookup === undefined) filterLookup = {};
        fg.filters().forEach((f) => {
          let checked: boolean = filterLookup[f.id] !== undefined && filterLookup[f.id].checked;
          f.checked.set(checked);
          f.children.forEach((c) => {
            checked = filterLookup[c.id] !== undefined && filterLookup[c.id].checked;
            c.checked.set(checked);
          });
        });
      });
    } else {
      let filterLookup: Dictionary<string, FilterV2Dto> = {};

      // First do categories

      let fg = this.filterGroups().find(x => x.name === 'Category')
      if (fg !== undefined) {
        filterLookup = filterGroupLookup[fg.name];
        if (filterLookup === undefined) filterLookup = {};

        fg.filters().forEach((f) => {
          let checked: boolean = filterLookup[f.id] !== undefined && filterLookup[f.id].checked;
          f.checked.set(checked);
        });
      }

      // Now kick off the hierarchical filters. These will reload the children if they are checked
      fg = null;
      switch (this.definition.context) {
        case FilterContext.MasterCatalog:
          fg = this.filterGroups().find(x => x.name === 'Department');
          break;
        case FilterContext.ProgramCatalog:
          fg = this.filterGroups().find(x => x.name === 'Program');
          break;
        case FilterContext.ProjectCatalog:
          fg = this.filterGroups().find(x => x.name === 'Project');
          break;
      }

      filterLookup = filterGroupLookup[fg.name];
      if (filterLookup === undefined) filterLookup = {};

      fg.filters().forEach((f) => {
        let checked: boolean = filterLookup[f.id] !== undefined && filterLookup[f.id].checked;
        f.checked.set(checked);
      });
      this.filterChecked(fg.name, filterGroupLookup);
    }

    this.evaluateFilters(true);
  }

  public setEdit(edit: boolean) {
    this.editMode.set(edit);
    if (edit && this.mode == FiltersPaneMode.Document && this.totalCheckedCount() == 0) this.collapsed.set(true);
  }

  evaluateFilters(moveCheckedToTop: boolean): void {
    this.filterGroups().forEach((fg) => {
      fg.evaluateFilters(moveCheckedToTop);
    });
  }

  resetCollapsed(): void {
    this.filterLists.forEach((list) => {
      list.collapsed.set(true);
    });
  }

  public uncheckAll(): void {
    this.filterGroups().forEach((fg) => {
      fg.uncheckAll();
    });
  }
}
