import {
  FilterContext,
  FilterGroupV2,
  FiltersPaneMode,
  FilterV2,
} from '../';

export class FiltersPanelDefinition {
  public panelOpen: boolean | undefined;

  constructor(
    public context: FilterContext,
    private mode: FiltersPaneMode,
    public filterGroups: FilterGroupV2[],
  ) {
    filterGroups.forEach((fg) => {
      fg.mode = mode;
    });
  }

  public setInitialChecked(checked: FilterV2[]) {
    checked.forEach((filter: FilterV2) => {
      if (filter.context == this.context) {
        this.filterGroups.forEach((group: FilterGroupV2) => {
          let f: FilterV2 = group.filters().find((f: FilterV2) => f.id == filter.id);
          if (f !== undefined) f.checked.set(true);
        });
      }
    });
    this.filterGroups.forEach((group: FilterGroupV2) => {
      group.evaluateFilters(true);
    });
  }
}