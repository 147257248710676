import {
  ReferenceType,
} from '../../_enums/referenceType';

import {
  FilterContext,
  FilterV2
} from '../';

export class FilterV2Dto {
  id: string;
  name: string;
  thumbnailUrl: string | null;
  referenceType: ReferenceType;
  parentId: string | null;
  group: string | null;
  checked: boolean;
  children: FilterV2Dto[];
  context: FilterContext;
  itemCount: number;
  type: string;

  public static fromFilterV2(filter: FilterV2): FilterV2Dto {
    let dto = new FilterV2Dto();
    dto.id = filter.id;
    dto.name = filter.name;
    dto.thumbnailUrl = filter.thumbnailUrl;
    dto.referenceType = filter.referenceType;
    dto.parentId = filter.parentId;
    dto.group = filter.group;
    dto.children = [];
    dto.checked = filter.checked();
    dto.context = filter.context;
    dto.itemCount = filter.itemCount;
    return dto;
  }
}