import {
  FilterContext,
  FilterV2,
} from '../';

import {
  Dictionary,
} from '../../_types/dictionary';

export class CheckedFilters {
  private lookup: Dictionary<number, FilterV2[]> = {};
  constructor(
  ) {
    this.lookup[FilterContext.Company] = [];
    this.lookup[FilterContext.MasterCatalog] = [];
    this.lookup[FilterContext.ProgramCatalog] = [];
    this.lookup[FilterContext.ProjectCatalog] = [];
  }

  public add(context: FilterContext, filters: FilterV2[]) {
    this.lookup[context] = filters;
  }

  public get(context: FilterContext): FilterV2[] {
    return this.lookup[context];
  }

  public total(): number {
    return Object.values(this.lookup).reduce((total, filters) => total + filters.length, 0);
  }

  public asString(): string {
    let str: string = '';
    this.lookup[FilterContext.Company].forEach((f) => {
      let token: string = this.filterAsString(f, FilterContext.Company);
      str = str + (str.length > 0 ? ',' : '') + token;
    });
    this.lookup[FilterContext.MasterCatalog].forEach((f) => {
      let token: string = this.filterAsString(f, FilterContext.MasterCatalog);
      str = str + (str.length > 0 ? ',' : '') + token;
    });
    this.lookup[FilterContext.ProgramCatalog].forEach((f) => {
      let token: string = this.filterAsString(f, FilterContext.ProgramCatalog);
      str = str + (str.length > 0 ? ',' : '') + token;
    });
    this.lookup[FilterContext.ProjectCatalog].forEach((f) => {
      let token: string = this.filterAsString(f, FilterContext.ProjectCatalog);
      str = str + (str.length > 0 ? ',' : '') + token;
    });
    return str;
  }

  private filterAsString(f: FilterV2, c: FilterContext): string {
    return c + '.' + f.referenceType + '.' + f.id;
  }
}
