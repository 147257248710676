<div [class]="visible() ? '' : 'hide'">
  <a style="float: right;top: -5px;position:relative;text-decoration:underline;"
     *ngIf="editMode() && mode != FiltersPaneMode.Navigation && showingAll()" (click)="reset()">Reset</a>
  <h6>{{ definition.name }} <span *ngIf="totalCheckedCount() > 0">({{ totalCheckedCount() }})</span></h6>

  <img *ngIf="definition.loading()" src="/assets/images/loading.gif" width="20" height="20" />

  <ng-container *ngIf="!definition.loading()">
    <div class="scroller">
      <ng-container *ngIf="totalVisibleCount() == 0 && editMode()">
        <ng-container *ngIf="definition.hierarchyParent !== undefined">Please select a {{ definition.hierarchyParentName() }} to show a filtered list, or <a style="text-decoration: underline;" (click)="showAll()">Show All</a></ng-container>
      </ng-container>
      <ng-container *ngFor="let filter of definition.filters(); let i = index">
        <div class="filter-item{{ filter.visible() ? '' : ' hide' }}">
          <input [disabled]="!editMode() || filter.implied()" type="checkbox"
                 [checked]="filter.checked() || filter.implied()"
                 (change)="filterChanged(filter, $event)" id="{{ filter.id }}" />
          <label for="{{ filter.id }}">{{ filter.name }}</label>
        </div>

        <ng-container *ngFor="let child of filter.children; let j = index">
          <div class="filter-item indented{{ child.visible() ? '' : ' hide' }}">
            <input [disabled]="!editMode() || child.implied()" type="checkbox"
                   [checked]="child.checked() || child.implied()"
                   (change)="filterChanged(child, $event)" id="{{ child.id }}" />
            <label for="{{ child.id }}">{{ child.name }}</label>
          </div>
        </ng-container>
      </ng-container>
    </div><!--{{ totalFilterCount() }} {{ totalVisibleCount() }} {{ totalEnabledCount() }} {{ collapsed() }}-->
    <ng-container *ngIf="definition.editMode()">
      <ng-container *ngIf="totalFilterCount() > 3">
        <span class="clickable" (click)="toggleCollapsed()">
          <ng-container *ngIf="!collapsed()">Less...</ng-container>
          <ng-container *ngIf="collapsed()">More...</ng-container>
        </span>
      </ng-container>
    </ng-container>
  </ng-container>
</div>