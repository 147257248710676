import { ReferenceType } from '../_enums/referenceType';

export var navigationIcons: { [index in ReferenceType]?: string } = {
    [ReferenceType.Company]: 'building',
    [ReferenceType.Project]: 'chart-bar',
    [ReferenceType.Floor]: 'layer-group',
    [ReferenceType.Department]: 'people-roof',
    [ReferenceType.Room]: 'person-booth',
    [ReferenceType.Component]: 'cog',
    [ReferenceType.AdvancedWorkPackage]: 'toolbox',
    [ReferenceType.Scenario]: 'city',
    [ReferenceType.Category]: 'tag',
    [ReferenceType.Collection]: 'database',
    [ReferenceType.Part]: 'person-booth',
    [ReferenceType.RoomContent]: 'chair',
    [ReferenceType.Program]: 'tv',
    [ReferenceType.RoomDataCategory]: 'tag'
};
