import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  signal,
  QueryList,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';

import {
  CheckedFilters,
  DocumentFilters,
  FilterContext,
  FilterGroupV2,
  FiltersPanelDefinition,
  FiltersPanelComponent,
  FiltersPaneMode,
  FilterService,
  FilterV2,
  FilterV2Dto,
  GetDocumentFiltersResponse,
  BaseFilters,
} from '../../';

import {
  Document
} from '../../../_models/document';

import {
  Dictionary
} from '../../../_types/dictionary';

import {
  LibraryNavigationService
} from '../../../_services/libraryNavigationService';

import {
  ReferenceType
} from '../../../_enums/referenceType';

@Component({
  selector: 'filters-pane',
  templateUrl: './filters-pane.component.html',
  styleUrls: ['filters-pane.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

@Injectable({
  providedIn: 'root'
})

export class FiltersPaneComponent implements OnInit {
  @ViewChildren(FiltersPanelComponent) filterPanels: QueryList<FiltersPanelComponent>;

  panels: WritableSignal<FiltersPanelDefinition[]> = signal<FiltersPanelDefinition[]>([]);

  @Input() mode: FiltersPaneMode;
  
  updating: WritableSignal<boolean> = signal<boolean>(false);

  @Input() document: Document | undefined;
  documentFilters: Dictionary<number, Dictionary<string, Dictionary<string, FilterV2Dto>>> = {};

  editMode: WritableSignal<boolean> = signal<boolean>(false);

  FiltersPaneMode = FiltersPaneMode;

  @Input() companyNav: WritableSignal<FilterGroupV2[]>;
  @Input() masterNav: WritableSignal<FilterGroupV2[]>;
  @Input() programNav: WritableSignal<FilterGroupV2[]>;
  @Input() projectNav: WritableSignal<FilterGroupV2[]>;

  @Input() initialFilters: FilterV2[];

  @Output() filterChecked = new EventEmitter<boolean>();
  @Output() panelOpened = new EventEmitter<FilterContext>();

  @Input() documentCount: number;

  definitions: FiltersPanelDefinition[] = [];

  @ViewChild('filterSearch') filterSearch: ElementRef;

  @Input() initialPanel: FilterContext | undefined;

  previousCollapseState: Dictionary<number, boolean> = {};
  previousSearch: string = '';
  constructor(
    public filterService: FilterService,
    public libraryNavigationService: LibraryNavigationService,
  ) {
    this.previousCollapseState[FilterContext.ProjectCatalog] = true;
    this.previousCollapseState[FilterContext.ProgramCatalog] = true;
    this.previousCollapseState[FilterContext.MasterCatalog] = true;
    this.previousCollapseState[FilterContext.Company] = true;
  }

  saveCollapseState() {
    let openCount = 0;
    this.filterPanels.forEach((panel) => {
      this.previousCollapseState[panel.definition.context] = panel.collapsed();
      if (panel.collapsed()) openCount++;
    });
    return openCount;
  }

  panelCollapseToggled(context: FilterContext) {
    let openCount = this.saveCollapseState();
    if (this.mode == FiltersPaneMode.Navigation) {
      let wasOpening: boolean = false;
      this.filterPanels.forEach((panel) => {
        if (panel.definition.context == context) wasOpening = true;
      });

      if (wasOpening) {
        this.filterPanels.forEach((panel) => {
          if (panel.definition.context !== context) panel.collapsed.set(true);
        });
      }
    }
    this.panelOpened.emit(context);
  }

  ngOnInit(): void {
    let fpd: FiltersPanelDefinition[] = [];

    if (this.mode != FiltersPaneMode.Navigation) {
      fpd.push(new FiltersPanelDefinition(FilterContext.Company, this.mode, this.filterService.baseFilters.companyFilters()));

      {
//        let groupC = new FilterGroupV2('Category', this.filterService.baseFilters.masterCategories);
        let groupD = new FilterGroupV2('Department', this.filterService.baseFilters.masterCatalogDepartments);
        let groupR = new FilterGroupV2('Room', signal<FilterV2[]>([]));
        groupD.setHierarchyChild(groupR, 'masterrooms');
        let groupRC = new FilterGroupV2('Room Content', signal<FilterV2[]>([]));
        groupR.setHierarchyChild(groupRC, 'masterroomcontent');
        fpd.push(new FiltersPanelDefinition(FilterContext.MasterCatalog, this.mode, [groupD, groupR, groupRC]));
      }

      {
//        let groupC = new FilterGroupV2('Category', this.filterService.baseFilters.programCategories);
        let groupP = new FilterGroupV2('Program', this.filterService.baseFilters.programs);
        let groupD = new FilterGroupV2('Department', signal<FilterV2[]>([]));
        groupP.setHierarchyChild(groupD, 'programdepartments');
        let groupR = new FilterGroupV2('Room', signal<FilterV2[]>([]));
        groupD.setHierarchyChild(groupR, 'programrooms');
        let groupRC = new FilterGroupV2('Room Content', signal<FilterV2[]>([]));
        groupR.setHierarchyChild(groupRC, 'programroomcontent');
        fpd.push(new FiltersPanelDefinition(FilterContext.ProgramCatalog, this.mode, [groupP, groupD, groupR, groupRC]));
      }

      {
        //let groupC = new FilterGroupV2('Category', this.filterService.baseFilters.projectCategories);
        let groupP = new FilterGroupV2('Project', this.filterService.baseFilters.projects);
        let groupD = new FilterGroupV2('Department', signal<FilterV2[]>([]));
        groupP.setHierarchyChild(groupD, 'projectdepartments');
        let groupR = new FilterGroupV2('Room', signal<FilterV2[]>([]));
        groupD.setHierarchyChild(groupR, 'projectrooms');
        let groupRC = new FilterGroupV2('Room Content', signal<FilterV2[]>([]));
        groupR.setHierarchyChild(groupRC, 'projectroomcontent');
        fpd.push(new FiltersPanelDefinition(FilterContext.ProjectCatalog, this.mode, [groupP, groupD, groupR, groupRC]));
      }


    } else {
      // Navigation filters are different, they don't load from the filter service
      // Take the companyNav filters and split them into their groups. The group name is specified by the group property
      // of the filter.
      let companyFilters: FilterGroupV2[] = [];
      fpd.push(new FiltersPanelDefinition(FilterContext.Company, this.mode, this.companyNav()));

      {
        let groupC = computed(() => {
          return this.masterNav().find((f) => f.name === 'Category');
        });
        let groupD = computed(() => {
          return this.masterNav().find((f) => f.name === 'Department');
        });
        let groupR = computed(() => {
          return this.masterNav().find((f) => f.name === 'Room');
        });
        groupD().setHierarchyChild(groupR(), 'masterrooms');
        let groupRC = computed(() => {
          return this.masterNav().find((f) => f.name === 'Room Content');
        });
        groupR().setHierarchyChild(groupRC(), 'masterroomcontent');
        fpd.push(new FiltersPanelDefinition(FilterContext.MasterCatalog, this.mode, [groupC(), groupD(), groupR(), groupRC()]));
      }

      {
        let groupC = computed(() => {
          return this.programNav().find((f) => f.name === 'Category');
        });
        let groupP = computed(() => {
          return this.programNav().find((f) => f.name === 'Program');
        });
        let groupD = computed(() => {
          return this.programNav().find((f) => f.name === 'Department');
        });
        let groupR = computed(() => {
          return this.programNav().find((f) => f.name === 'Room');
        });
        let groupRC = computed(() => {
          return this.programNav().find((f) => f.name === 'Room Content');
        });
        groupP().setHierarchyChild(groupD(), 'programdepartments');
        groupD().setHierarchyChild(groupR(), 'programrooms');
        groupR().setHierarchyChild(groupRC(), 'programroomcontent');
        fpd.push(new FiltersPanelDefinition(FilterContext.ProgramCatalog, this.mode, [groupC(), groupP(), groupD(), groupR(), groupRC()]));
      }

      {
        let groupC = computed(() => {
          return this.projectNav().find((f) => f.name === 'Category');
        });
        let groupP = computed(() => {
          return this.projectNav().find((f) => f.name === 'Project');
        });
        let groupD = computed(() => {
          return this.projectNav().find((f) => f.name === 'Department');
        });
        groupP().setHierarchyChild(groupD(), 'projectdepartments');
        let groupR = computed(() => {
          return this.projectNav().find((f) => f.name === 'Room');
        });
        groupD().setHierarchyChild(groupR(), 'projectrooms');
        let groupRC = computed(() => {
          return this.projectNav().find((f) => f.name === 'Room Content');
        });
        groupR().setHierarchyChild(groupRC(), 'projectroomcontent');
        fpd.push(new FiltersPanelDefinition(FilterContext.ProjectCatalog, this.mode, [groupC(), groupP(), groupD(), groupR(), groupRC()]));
      }

      if (this.initialFilters !== undefined) {
        fpd.forEach((def: FiltersPanelDefinition) => {
          def.setInitialChecked(this.initialFilters);
        });
      }
    }

    if (this.mode !== FiltersPaneMode.Document) this.editMode.set(true);
    this.definitions = fpd;
    this.panels.set(fpd);

    if (this.document !== undefined) this.documentSelected(this.document);
    if (this.mode == FiltersPaneMode.Navigation) {
      this.filterService.checkedNavigationFilters = this.getCheckedFiltersGrouped();
    }

    if (this.initialPanel !== undefined) {
      this.panels().forEach((panel) => {
        panel.panelOpen = panel.context === this.initialPanel;
      });
    }
  }

  searchFilters(target: EventTarget | null) {
    if (this.previousSearch.length == 0) this.saveCollapseState();
    const inputElement = target as HTMLInputElement;
    let text = inputElement.value;
    this.previousSearch = text;
    this.filterPanels.forEach((panel) => {
      panel.search(text);
    });
    if (text == '') {
      // restore previous collapsed states
      this.filterPanels.forEach((panel) => {
        panel.collapsed.set(this.previousCollapseState[panel.definition.context]);
      });
    }
  }

  public filterClicked(): void {
    this.filterChecked.emit(true);
    if (this.mode == FiltersPaneMode.Navigation) {
      this.filterService.checkedNavigationFilters = this.getCheckedFiltersGrouped();
    }
  }

  public uncheckAll(): void {
    this.filterPanels.forEach((panel) => {
      panel.uncheckAll();
    });
  }

  public getCheckedFilters(): CheckedFilters {
    let checked: CheckedFilters = new CheckedFilters();
    this.filterPanels.forEach((panel) => {
      checked.add(panel.definition.context, panel.getCheckedFilters());
    });
    return checked;
  }

  public getCheckedFiltersGrouped(): Dictionary<number, Dictionary<string, Dictionary<string, FilterV2Dto>>> {
    let result: Dictionary<number, Dictionary<string, Dictionary<string, FilterV2Dto>>> = {};
    this.definitions.forEach((def) => {
      if (result[def.context] === undefined) result[def.context] = {};
      def.filterGroups.forEach((group) => {
        if (result[def.context][group.name] === undefined) result[def.context][group.name] = {};
        group.filters().forEach((filter) => {
          if (filter.checked()) {
            result[def.context][group.name][filter.id] = FilterV2Dto.fromFilterV2(filter);
          }
        });
      });
    });
    return result;
  }
  
  updateFilters() {
    var checked = this.getCheckedFilters();
    this.updating.set(true);
    this.filterService.updateDocumentFilters(this.document.id, checked.get(FilterContext.Company),
      checked.get(FilterContext.MasterCatalog), checked.get(FilterContext.ProgramCatalog),
      checked.get(FilterContext.ProjectCatalog)).subscribe((response) => {
      this.setDocumentFilters(response);
      this.updating.set(false);
      this.editFilters(false);
    });
  }
  
  public setCheckedFilters(checked: Dictionary<number, Dictionary<string, Dictionary<string, FilterV2Dto>>>): void {
    this.filterPanels.forEach((panel) => {
      panel.setCheckedFilters(checked);
    });
  }

  private setDocumentFilters(response: GetDocumentFiltersResponse) {
    this.documentFilters = {};

    if (response.companyFilters.length > 0) {
      this.documentFilters[FilterContext.Company] = {};
      response.companyFilters.forEach((filterGroup) => {
        if (this.documentFilters[FilterContext.Company][filterGroup.name] === undefined)
          this.documentFilters[FilterContext.Company][filterGroup.name] = {};
        filterGroup.filters.forEach((filter) => {
          this.documentFilters[FilterContext.Company][filterGroup.name][filter.id] = filter;
        });
      });
    }

    if (response.masterCategories.length > 0 || response.masterDepartments.length > 0
      || response.masterRooms.length > 0 || response.masterRoomContents.length > 0) {
      this.documentFilters[FilterContext.MasterCatalog] = {};
      if (response.masterCategories.length > 0) {
        this.documentFilters[FilterContext.MasterCatalog]['Category'] = {};
        response.masterCategories.forEach((filter) => {
          this.documentFilters[FilterContext.MasterCatalog]['Category'][filter.id] = filter;
        });
      }
      if (response.masterDepartments.length > 0) {
        this.documentFilters[FilterContext.MasterCatalog]['Department'] = {};
        response.masterDepartments.forEach((filter) => {
          this.documentFilters[FilterContext.MasterCatalog]['Department'][filter.id] = filter;
        });
      }
      if (response.masterRooms.length > 0) {
        this.documentFilters[FilterContext.MasterCatalog]['Room'] = {};
        response.masterRooms.forEach((filter) => {
          this.documentFilters[FilterContext.MasterCatalog]['Room'][filter.id] = filter;
        });
      }
      if (response.masterRoomContents.length > 0) {
        this.documentFilters[FilterContext.MasterCatalog]['Room Content'] = {};
        response.masterRoomContents.forEach((filter) => {
          this.documentFilters[FilterContext.MasterCatalog]['Room Content'][filter.id] = filter;
        });
      }
    }

    if (response.programCategories.length > 0 || response.programPrograms.length > 0
      || response.programDepartments.length > 0 || response.programRooms.length > 0
      || response.programRoomContents.length > 0) {
      this.documentFilters[FilterContext.ProgramCatalog] = {};
      if (response.programCategories.length > 0) {
        this.documentFilters[FilterContext.ProgramCatalog]['Category'] = {};
        response.programCategories.forEach((filter) => {
          this.documentFilters[FilterContext.ProgramCatalog]['Category'][filter.id] = filter;
        });
      }
      if (response.programPrograms.length > 0) {
        this.documentFilters[FilterContext.ProgramCatalog]['Program'] = {};
        response.programPrograms.forEach((filter) => {
          this.documentFilters[FilterContext.ProgramCatalog]['Program'][filter.id] = filter;
        });
      }
      if (response.programDepartments.length > 0) {
        this.documentFilters[FilterContext.ProgramCatalog]['Department'] = {};
        response.programDepartments.forEach((filter) => {
          this.documentFilters[FilterContext.ProgramCatalog]['Department'][filter.id] = filter;
        });
      }
      if (response.programRooms.length > 0) {
        this.documentFilters[FilterContext.ProgramCatalog]['Room'] = {};
        response.programRooms.forEach((filter) => {
          this.documentFilters[FilterContext.ProgramCatalog]['Room'][filter.id] = filter;
        });
      }
      if (response.programRoomContents.length > 0) {
        this.documentFilters[FilterContext.ProgramCatalog]['Room Content'] = {};
        response.programRoomContents.forEach((filter) => {
          this.documentFilters[FilterContext.ProgramCatalog]['Room Content'][filter.id] = filter;
        });
      }
    }

    if (response.projectCategories.length > 0 || response.projectProjects.length > 0
      || response.projectDepartments.length > 0 || response.projectRooms.length > 0
      || response.projectRoomContents.length > 0) {
      this.documentFilters[FilterContext.ProjectCatalog] = {};
      if (response.projectCategories.length > 0) {
        this.documentFilters[FilterContext.ProjectCatalog]['Category'] = {};
        response.projectCategories.forEach((filter) => {
          this.documentFilters[FilterContext.ProjectCatalog]['Category'][filter.id] = filter;
        });
      }
      if (response.projectProjects.length > 0) {
        this.documentFilters[FilterContext.ProjectCatalog]['Project'] = {};
        response.projectProjects.forEach((filter) => {
          this.documentFilters[FilterContext.ProjectCatalog]['Project'][filter.id] = filter;
        });
      }
      if (response.projectDepartments.length > 0) {
        this.documentFilters[FilterContext.ProjectCatalog]['Department'] = {};
        response.projectDepartments.forEach((filter) => {
          this.documentFilters[FilterContext.ProjectCatalog]['Department'][filter.id] = filter;
        });
      }
      if (response.projectRooms.length > 0) {
        this.documentFilters[FilterContext.ProjectCatalog]['Room'] = {};
        response.projectRooms.forEach((filter) => {
          this.documentFilters[FilterContext.ProjectCatalog]['Room'][filter.id] = filter;
        });
      }
      if (response.projectRoomContents.length > 0) {
        this.documentFilters[FilterContext.ProjectCatalog]['Room Content'] = {};
        response.projectRoomContents.forEach((filter) => {
          this.documentFilters[FilterContext.ProjectCatalog]['Room Content'][filter.id] = filter;
        });
      }
    }

    this.editFilters(false);
    this.filterPanels.forEach((panel) => {
      panel.setCheckedFilters(this.documentFilters);
    });
  }

  documentSelected(doc: Document) {
    this.document = doc;
    if (doc !== null && doc !== undefined) {
      this.filterService.getDocumentFilters(doc.id).subscribe((response) => {
        this.setDocumentFilters(response);
        this.filterSearch.nativeElement.value = '';
        this.filterPanels.forEach((panel) => {
          panel.search('');
        });
      });
    }
  }

  editFilters(edit: boolean) {
    this.filterPanels.forEach((panel) => {
      panel.setEdit(edit);
    });
    if (!edit) this.resetCollapsed();
    this.evaluateFilters();
  }

  private resetCollapsed(): void {
    this.filterPanels.forEach((panel) => {
      panel.resetCollapsed();
    });
  }

  private evaluateFilters(): void {
    this.filterPanels.forEach((panel) => {
      panel.evaluateFilters(false);
    });
  }
}








