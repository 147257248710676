//angular imports
import {
  computed,
  Injectable,
  signal,
  WritableSignal,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

//rxjs imports
import { Observable } from 'rxjs';

//local imports
import { AppConfig } from '../../app-config';
import { Dictionary } from '../../_types/dictionary';
import {
  BaseFilters,
  CheckedFilters,
  EmptyGuid,
  FilterV2,
  FilterV2Dto,
  GetAllFiltersResponse,
  GetDocumentFiltersResponse,
} from '../';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private baseUrl: string;
  public baseFilters: BaseFilters;

  public checkedNavigationFilters: Dictionary<number, Dictionary<string, Dictionary<string, FilterV2Dto>>>;

  constructor(
    private http: HttpClient,
    private applicationConfig: AppConfig,
  ) {
    this.baseUrl = `${this.applicationConfig.applicationUri}/v2/libraryfilters`;
    this.baseFilters = new BaseFilters();
    this.checkedNavigationFilters = {};
    this.getAllFilters().subscribe((response) => {
      this.baseFilters.update(response);
    });
  }

  private getAllFilters(): Observable<GetAllFiltersResponse> {
    return this.http.get<GetAllFiltersResponse>(`${this.baseUrl}`);
  }

  public updateDocumentFilters(documentId: string, company: FilterV2[],
                               master: FilterV2[], program: FilterV2[], project: FilterV2[])
    : Observable<GetDocumentFiltersResponse> {
    company = company.filter(item => item.id !== EmptyGuid);
    master = master.filter(item => item.id !== EmptyGuid);
    program = program.filter(item => item.id !== EmptyGuid);
    project = project.filter(item => item.id !== EmptyGuid);
    return this.http.patch<GetDocumentFiltersResponse>
      (`${this.baseUrl}/${documentId}/true`,
        { company: company, master: master, program: program, project: project });
  }

  public getDocumentFilters(documentId: string)
    : Observable<GetDocumentFiltersResponse> {
    return this.http.get<GetDocumentFiltersResponse>
      (`${this.baseUrl}/${documentId}`);
  }
  
  public getNextLevelFilters(urlStub: string, filters: FilterV2[][])
    : Observable<FilterV2Dto[]> {
    let url: string = `${this.baseUrl}/${urlStub}`;
    filters.forEach((filterList: FilterV2[]) => {
      let ids = filterList.map(f => f.id).join(',');
      if (ids.length == 0 || ids === undefined) ids = EmptyGuid;
      url = url + `/${ids}`;
    });
    return this.http.get<FilterV2Dto[]>(url);
  }
}



