//angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//local imports
import {
  FilterGroupV2Dto
} from '../filters';
import { ReferenceType } from '../_enums/referenceType';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, private applicationConfig: AppConfig) {    
  }

  getAll(referenceType: ReferenceType) {
    return this.http.get<FilterGroupV2Dto[]>(`${this.applicationConfig.applicationUri}/v2/category/getall/${referenceType}`);
  }

  addCategory(name: string, parentId: string, referenceType: ReferenceType, categoryGroupId: string) {
    if (parentId == '0') parentId = '00000000-0000-0000-0000-000000000000';
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/category/addcategory`, { Name: name, ParentId: parentId, ReferenceType: referenceType, CategoryGroupId: categoryGroupId });
  }

  editCategory(id: string, name: string, parentId: string, categoryGroupId: string) {
    if (parentId == '0') parentId = '00000000-0000-0000-0000-000000000000';
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/category/editcategory`, { Id: id, Name: name, ParentId: parentId, CategoryGroupId: categoryGroupId });
  }

  deleteCategory(id: string) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/category/deletecategory`, { Id: id });
  }

  addCategoryGroup(name: string) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/category/addcategorygroup`, { Name: name });
  }
  editCategoryGroup(id: string, name: string) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/category/editcategorygroup`, { Id: id, Name: name });
  }
  deleteCategoryGroup(id: string) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/category/deletecategorygroup`, { Id: id });
  }

}
