import {
  NgModule
} from '@angular/core';

import {
  CommonModule
} from '@angular/common';

import {
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome';

import {
  FiltersPaneComponent,
  FiltersPanelComponent,
  FiltersListComponent,
} from './';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  declarations: [
    FiltersPaneComponent,
    FiltersPanelComponent,
    FiltersListComponent,
  ],
  exports: [
    FiltersPaneComponent,
    FiltersPanelComponent,
  ]
})

export class FiltersModule { }