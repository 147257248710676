<div class="filters-pane">
  <div *ngIf="mode == FiltersPaneMode.Document && editMode()">
    <a class="action-button" (click)="updateFilters()">
      <fa-icon [icon]="['fas', 'cloud-upload-alt']" size="s"></fa-icon>&nbsp;<span>Update</span>
    </a>
    <a class="action-button" (click)="editFilters(false)">
      <fa-icon [icon]="['fas', 'minus-circle']" size="s"></fa-icon>&nbsp;<span>Cancel</span>
    </a>
  </div>
  <input type="text" placeholder="Search Filters" (input)="searchFilters($event.target)" #filterSearch />
  <ng-container *ngFor="let panel of panels()">
    <filters-panel [definition]="panel" 
                   [editMode]="editMode"
                   [mode]="mode"
                   [documentCount]="documentCount"
                   (panelCollapseToggled)="panelCollapseToggled($event)"
                   (filterClicked)="filterClicked()"
                   ></filters-panel>
  </ng-container>
</div>
