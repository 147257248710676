import {
  signal,
  WritableSignal,
} from '@angular/core';

import {
  FilterGroupV2,
  FilterV2,
  GetAllFiltersResponse,
} from '../';

export class BaseFilters {
  companyFilters: WritableSignal<FilterGroupV2[]>;
  masterCategories: WritableSignal<FilterV2[]>;
  programCategories: WritableSignal<FilterV2[]>;
  projectCategories: WritableSignal<FilterV2[]>;
  masterCatalogDepartments: WritableSignal<FilterV2[]>;
  programs: WritableSignal<FilterV2[]>;
  projects: WritableSignal<FilterV2[]>;

  constructor() {
    this.companyFilters = signal<FilterGroupV2[]>([]);
    this.masterCategories = signal<FilterV2[]>([]);
    this.programCategories = signal<FilterV2[]>([]);
    this.projectCategories = signal<FilterV2[]>([]);
    this.masterCatalogDepartments = signal<FilterV2[]>([]);
    this.programs = signal<FilterV2[]>([]);
    this.projects = signal<FilterV2[]>([]);
  }

  update(allFilters: GetAllFiltersResponse): void {
    this.companyFilters.set(FilterGroupV2.fromFilterGroupV2Dtos(allFilters.companyFilters));

    this.masterCategories.set(FilterV2.FromFilterV2Dtos(allFilters.masterCatalogCategories));
    this.programCategories.set(FilterV2.FromFilterV2Dtos(allFilters.programCatalogCategories));
    this.projectCategories.set(FilterV2.FromFilterV2Dtos(allFilters.projectCatalogCategories));

    this.programs.set(FilterV2.FromFilterV2Dtos(allFilters.programs));
    this.projects.set(FilterV2.FromFilterV2Dtos(allFilters.projects));

    this.masterCatalogDepartments.set(FilterV2.FromFilterV2Dtos(allFilters.masterCatalogDepartments));
  }
}
