<div class="section-header clickable{{ totalVisibleCount() == 0 ? ' hide' : '' }}" (click)="toggleCollapsed()">
  {{ heading() }}
  <ng-container *ngIf="selectedCount() > 0"> ({{ selectedCount() }})</ng-container>
  <fa-icon [icon]="['fas', 'chevron-circle-up']" size="s" *ngIf="!collapsed()"></fa-icon>
  <fa-icon [icon]="['fas', 'chevron-circle-down']" size="s" *ngIf="collapsed()"></fa-icon>
</div>
<div [class]="(totalVisibleCount() == 0 || collapsed() ? 'hide ' : '') + 'padded-base'">
  <ng-container *ngFor="let group of filterGroups()">
    <ng-container *ngIf="mode != FiltersPaneMode.Navigation || group.filters().length > 0">
      <filters-list [definition]="group"
                    [editMode]="editMode"
                    [mode]="mode"
                    [documentCount]="documentCount"
                    (filterChecked)="filterChecked($event)"></filters-list>
    </ng-container>
  </ng-container>
</div>